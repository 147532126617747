import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import { Fade } from "react-reveal";
import { DatePicker, Button, Timeline } from "antd";

export default function Skills(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <DatePicker></DatePicker>
          <Button type="dashed">Button</Button>

          <h1>What is VODE </h1>
          <h2>how do we work</h2>
          <Timeline
            items={[
              {
                children: "Open and account",
              },
              {
                children: "Setup your profiles",
              },
              {
                children:
                  "Create your home, virtual space, and pin your favorite locations",
              },
              {
                children: "Create and interact with other locations",
              },
            ]}
          />
          {/*
          <h1 className="skills-header" style={{ color: theme.text}}style={{ color: theme.text}}>
            What is VODE?
          </h1>
        */}
        </Fade>
      </div>
      <SkillSection theme={theme} />
    </div>
  );
}
